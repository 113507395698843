// ** Custom Components
import Icon from "components/global/Icon";

// ** SVG Static Imports
import { ReactComponent as SolidSpinner } from "resources/icons/solid/spinner.svg";

// #####################################################

const LoadingComponent = ({ alwaysLight }) => (
	<Icon
		svgIconComponent={SolidSpinner}
		spin
		pulse
		size="lg"
		alwaysLight={alwaysLight}
	>
		spinner
	</Icon>
);

export default LoadingComponent;

// #####################################################
